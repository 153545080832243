<template>
  <div class="">
    <!-- section-1  -->
    <div class="white mb-10 box-shadow">
      <v-container class="p-relative">
        <v-row>
          <!-- <v-col cols="12" xl="2" lg="3" class="pt-0 d-none d-lg-block">
            <Navbar />
          </v-col> -->
          <v-col cols="12" xl="7" lg="7">
            <v-carousel
              class="vuetify-carousel"
              cycle
              height="100%"
              hide-delimiter-background
              show-arrows-on-hover
              :show-arrows="false"
            >
              <v-carousel-item v-for="(n, index) in heroSlider" :key="index">
                <v-sheet height="100%" light>
                  <v-row class="fill-height" align="center">
                    <v-col cols="12" lg="7" class="mb-14 mb-sm-0 mt-n12">
                      <v-col cols="12" class="ms-3 ps-0">
                        <h1 class="show-3 font-weight-bold mb-4">
                          {{ n.name }}
                        </h1>
                      </v-col>
                      <v-col cols="12" lg="8">
                        <p class="secondary--text banner-text mb-4">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Quis lobortis consequat eu, quam etiam at quis
                          ut convallis.
                        </p>
                      </v-col>
                    </v-col>
                    <v-col cols="12" lg="5">
                      <v-img aspect-ratio="1" contain :src="n.img" style="height: 18vw;"></v-img>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <div class="p-absolute" style="right: 0;">
            <div class="pa-5 pr-0 mr-2" style="width: 27vw;">
              <v-row class="match-height">
                <div class="pa-3 align-end float-left" v-for="(n, index) in bannerView" :key="index">
                  <div class="banner__item p-relative w-img mb-30">
                    <div class="banner__img banner__img-2 b-radius-2">
                      <v-img height="8vw" :src="n.img" alt=""/>
                    </div>
                    <v-col cols="12" md="10" class="banner__content">
                      <h6>{{ n.heading }}</h6>
                      <p class="banner-p mt-30">{{ n.sub }}</p>
                    </v-col>
                  </div>
                </div>
              </v-row>
            </div>
          </div>
        </v-row>
      </v-container>
    </div>

    <!-- section-2  -->
    <div class="container mb-3">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between title-border align-center">
            <div class="d-flex align-center">
              <h2 class="font-weight-bold title-h2">Top Categories</h2>
            </div>
            <a href="#" class="text-decoration-none blue-grey--text darken-1">
              View all
              <v-icon class="m-0 p-0">mdi-chevron-right</v-icon>
            </a>
          </div>
        </v-col>
        <v-col cols="12" class="px-0">
          <div class="">
            <VueSlickCarousel
              class="slick-secondary"
              :arrows="true"
              :dots="false"
              v-bind="slickSettings"
            >
              <v-col cols="12" v-for="(item, index) in getProducts.slice(16, 24).map(n => n)" :key="index">
                <CardCart
                  :content-img="item.img"
                  :content-text="item.title"
                  :counter="item.qty"
                  class="m-5"
                  @cartRemove="removeCart(item)"
                  @cartAdd="addCart(item)"
                />
              </v-col>

              <template #prevArrow="arrowOption">
                <v-btn
                  class="mx-4 slick-arrow prev slick-prev box-shadow-none"
                  fab
                  dark
                  small
                  color="secondary"
                >
                  <v-icon dark>
                    {{ arrowOption }}
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <template #nextArrow="arrowOption">
                <v-btn
                  class="mx-4 slick-arrow next slick-next box-shadow-none"
                  fab
                  dark
                  small
                  color="secondary"
                >
                  <v-icon dark>
                    {{ arrowOption }}
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </template>
            </VueSlickCarousel>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import Navbar from "@/views/home/navbar";
  import CardCart from "@/components/cartCard/CardCart";
  import { mapGetters, mapActions} from 'vuex';
  export default {
    // name: 'Home',
    components: {
      Navbar,
      CardCart,
    },
    computed: {
      ...mapGetters(['getProducts']),
    },
    data () {
      return {
        slickSettings: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 2100,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                infinite: true,
              },
            },
            {
              breakpoint: 1900,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1800,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        slickSettingsTwo: {
          infinite: false,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
          ],
        },
        slickSettingsThree: {
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 6,
          initialSlide: 0,

          responsive: [
            {
              breakpoint: 1700,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                infinite: true,
              },
            },
            {
              breakpoint: 1264,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
              },
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ],
        },
        brandsIcon: [
          {
            name: 'TMW',
            image: require('@/assets/images/brands/v.png'),
          },
          {
            name: 'Zerrari',
            image: require('@/assets/images/brands/u.png'),
          },
          {
            name: 'Zord',
            image: require('@/assets/images/brands/v.png'),
          },
          {
            name: 'Gini',
            image: require('@/assets/images/brands/u.png'),
          },
          {
            name: 'Nesla',
            image: require('@/assets/images/brands/v.png'),
          },
        ],
        topRatings: [
          {
            img: require('@/assets/images/products/camera-1.png'),
            title: 'Camera',
            price: '$3,300',
          },
          {
            img: require('@/assets/images/products/shoes-2.png'),
            title: 'Shoe',
            price: '$400',
          },
          {
            img: require('@/assets/images/products/mobile-1.png'),
            title: 'Mobile',
            price: '$999',
          },
          {
            img: require('@/assets/images/products/watch-1.png'),
            title: 'Watch',
            price: '$600',
          },
        ],
        newArrivals: [
          {
            img: require('@/assets/images/products/imagegoggles.png'),
            title: 'Sunglass',
            price: '$150',
          },
          {
            img: require('@/assets/images/products/lipstick(2).png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(2).png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/bgwatch.png'),
            title: 'Lipstick',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(4).png'),
            title: 'Green Plant',
            price: '$250',
          },
          {
            img: require('@/assets/images/products/lipstick(3).png'),
            title: 'Bonsai Tree',
            price: '$450',
          },
        ],
        bigDiscounts: [
          {
            img: require('@/assets/images/products/Electronics/7.beatsw3.png'),
            title: 'BenX 2020',
          },
          {
            img: require('@/assets/images/products/Electronics/9.SonyTV1080p.png'),
            title: 'Tony TV 1080p',
          },
          {
            img: require('@/assets/images/products/Electronics/10.SonyPS4.png'),
            title: 'Tony PS4',
          },
          {
            img: require('@/assets/images/products/Electronics/11.Netgear2020.png'),
            title: 'Setgearr 2020',
          },
          {
            img: require('@/assets/images/products/Electronics/12.SonyBGB.png'),
            title: 'Tony BGB',
          },
          {
            img: require('@/assets/images/products/Electronics/13.LGProducts.png'),
            title: 'RG Products',
          },
          {
            img: require('@/assets/images/products/Electronics/14.Panasonic2019.png'),
            title: 'Sanasonic 2019',
          },
          {
            img: require('@/assets/images/products/Electronics/15.DuneHD.png'),
            title: 'Zune HD',
          },
          {
            img: require('@/assets/images/products/Electronics/19.LGWashingMachine.png'),
            title: 'JK Washing Machine',
          },
        ],
        topCategories: [
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-2.png'),
          },
          {
            img: require('@/assets/images/banners/category-3.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
          {
            img: require('@/assets/images/banners/category-2.png'),
          },
          {
            img: require('@/assets/images/banners/category-3.png'),
          },
          {
            img: require('@/assets/images/banners/category-1.png'),
          },
        ],
        featuredBrands: [
          {
            img: require('@/assets/images/products/london-britches.png'),
            title: 'London Britches',
          },
          {
            img: require('@/assets/images/products/jimandjiko.png'),
            title: 'Jim & Jago',
          },
        ],
        categories: [
          {
            img: require('@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png'),
            title: 'Tops',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png'),
            title: 'Smart Watch',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/10.RayBanOcean.png'),
            title: 'Sunglasses',
          },
          {
            img: require('@/assets/images/products/Fashion/Accessories/3.BrownCap.png'),
            title: 'Cap',
          },
          {
            img: require('@/assets/images/products/Fashion/Shoes/1.NikeRed.png'),
            title: 'Nike Red',
          },
          {
            img: require('@/assets/images/products/Fashion/Shoes/5.NikeBlue.png'),
            title: 'Nike Silver',
          },
          {
            img: require('@/assets/images/products/Fashion/Jewellery/1.Heavy22ktGoldNecklaceSet.png'),
            title: 'Gold Necklace',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/3.NBLHoopHoodie.png'),
            title: 'NBL Hoodie',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/1.SilverHighNeckSweater.png'),
            title: 'Tops',
          },
          {
            img: require('@/assets/images/products/Fashion/Clothes/10.LandsJacket.png'),
            title: 'Jacket',
          },
        ],
        heroSlider: [
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: '50% Off For Your First Shopping',
          },
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: ' 20% Off For  First Electronics Purchase',
          },
          {
            img: require('@/assets/images/products/apple-watch-0.png'),
            name: 'Get 70% Off For  First  Grocery Purchase',
          },
        ],
        bannerView: [
          {
            img: require('@/assets/images/banners/banner-3.jpg'),
            heading: 'Canyon Star Raider',
            sub: 'Headphone & Audio',
          },
          {
            img: require('@/assets/images/banners/banner-1.jpg'),
            heading: 'New Style Bluetooh Speaker',
            sub: 'Free Shipping 20km',
          },
          {
            img: require('@/assets/images/banners/banner-4.jpg'),
            heading: 'Galaxy Buds Plus White',
            sub: 'Headphone & Audio',
          },
          {
            img: require('@/assets/images/banners/banner-11.jpg'),
            heading: 'Limited Top Camera',
            sub: 'Free Shipping 20km',
          },
        ],
        tab: null,
        items: ['Brands', 'Shops'],
      }
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
    },
  }
</script>

<style lang="scss" scoped>
  .top-categories-img {
    width: 385px;
    min-width: 100%;
    max-width: 100%;
  }
</style>
